<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th>Hostname</th>
                <td colspan="2">
                  {{ asup.hostname }}
                </td>
              </tr>
              <tr>
                <th>Serialnumber</th>
                <td colspan="2">
                  {{ asup.serial }}
                </td>
              </tr>
              <tr v-if="asup.details.dellServiceTag">
                <th>Dell Service Tag</th>
                <td colspan="2">
                  {{ asup.details.dellServiceTag }}
                </td>
              </tr>
              <tr>
                <th>Model</th>
                <td colspan="2">
                  {{ asup.model }}
                </td>
              </tr>
              <tr>
                <th>Version</th>
                <td colspan="2">
                  {{ asup.version }}
                </td>
              </tr>
              <tr v-if="asup.details.location">
                <th>Location</th>
                <td colspan="2">
                  {{ asup.details.location }}
                </td>
              </tr>

              <tr>
                <th>System Timezone</th>
                <td colspan="2">
                  {{ asup.timezone }}
                </td>
              </tr>
              <tr>
                <th>Last autosupport data</th>
                <td colspan="2">
                  {{ asup.updateTimestampUtc | formatDateTime }}
                </td>
              </tr>
              <tr v-if="asup.updateTimestampUtc > asup.lastBuildModelTimestamp">
                <th>Model built on (UTC)</th>
                <td colspan="2">
                  {{ asup.lastBuildModelTimestamp | formatDateTime }}<br>
                  <small class="text-hint text-danger">The displayed data is older than the latest Autosupport information. The displayed data should be updated a few minutes after the latest Autosupport data.</small>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr>
                </td>
              </tr>

              <tr v-if="asup.details.licensedCapacity">
                <th>Licensed Capacity</th>
                <td>
                  {{ asup.details.licensedCapacity | prettyBytes }} ({{ asup.details.licensedCapacity | prettyBytes({ binary: true}) }})
                  <template v-if="asup.model === 'DD6300'">
                    <feather-icon
                      v-b-popover.hover="'Licenced capacity for DD6300 excludes capacity in the head which is licenced by default.'"
                      icon="AlertCircleIcon"
                      class="ml-1"
                    />
                  </template>
                </td>
              </tr>

              <tr>
                <th>Used Capacity</th>
                <template v-if="lastStatistic.usedInPercent">
                  <td>
                    {{ lastStatistic.usedInPercent }}% <br>
                    {{ lastStatistic.usedBytes | prettyBytes }} of {{ lastStatistic.capacityBytes | prettyBytes }} <br>
                    {{ lastStatistic.usedBytes | prettyBytes({ binary: true}) }} of {{ lastStatistic.capacityBytes | prettyBytes({ binary: true}) }} <br>
                  </td>
                  <td :rowspan="lastStatistic.cleanableBytes ? 2 : 1">
                    <apexchart
                      type="radialBar"
                      height="70"
                      width="70"
                      :options="capacityChartOptions"
                      :series="[lastStatistic.usedInPercent]"
                    />
                  </td>
                </template>
              </tr>

              <tr v-if="lastStatistic.cleanableBytes">
                <th>Cleanable</th>
                <td>
                  {{ lastStatistic.cleanableBytes | prettyBytes }} ({{ lastStatistic.cleanableBytes | prettyBytes({ binary: true}) }})
                </td>
              </tr>

              <tr v-if="asup.details.calculated.usageExceedsLimitBase365On">
                <th>Usage exceeds limit of 90% on <small class="text-muted">(Base 365 days)</small></th>
                <td>
                  {{ asup.details.calculated.usageExceedsLimitBase365On | formatDate }}
                </td>
              </tr>

              <tr v-if="asup.details.calculated.usageExceedsLimitBase90On">
                <th>Usage exceeds limit of 90% on <small class="text-muted">(Base 90 days)</small></th>
                <td>
                  {{ asup.details.calculated.usageExceedsLimitBase90On | formatDate }}
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <hr>
                </td>
              </tr>

              <tr>
                <th>Deduplication Factor</th>
                <td colspan="2">
                  {{ lastStatistic.factor }}
                </td>
              </tr>

              <tr>
                <th>PreComp</th>
                <td colspan="2">
                  {{ lastStatistic.preCompBytes | prettyBytes }} ({{ lastStatistic.preCompBytes | prettyBytes({ binary: true}) }})
                </td>
              </tr>

              <template v-if="asup.details.availability">
                <tr>
                  <td colspan="3">
                    <hr>
                  </td>
                </tr>

                <tr>
                  <th>Available since</th>
                  <td colspan="2">
                    {{ asup.details.availability.since | formatTimeSpanFromNow }} <small>({{ asup.details.availability.since | formatDateTimeTz(asup.timezone) }})</small>
                  </td>
                </tr>

                <tr>
                  <th class="pl-3">
                    System
                  </th>
                  <td colspan="2">
                    {{ asup.details.availability.systemExControlledDowntimePercentage }}% <small>({{ asup.details.availability.systemPercentage }}%)</small>
                    <feather-icon
                      v-b-popover.hover="`${asup.details.availability.systemExControlledDowntimePercentage}% excludes controlled downtime.`"
                      class="ml-1"
                      icon="HelpCircleIcon"
                    />
                  </td>
                </tr>

                <tr>
                  <th class="pl-3">
                    Filesystem
                  </th>
                  <td colspan="2">
                    {{ asup.details.availability.filesystemExControlledDowntimePercentage }}% <small>({{ asup.details.availability.filesystemPercentage }}%)</small>
                    <feather-icon
                      v-b-popover.hover="`${asup.details.availability.filesystemExControlledDowntimePercentage}% excludes controlled downtime.`"
                      class="ml-1"
                      icon="HelpCircleIcon"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <template #header>
            <h4 class="card-title">
              Monitor Status <small class="text-muted">({{ monitors.length }} monitors checked)</small>
            </h4>
            <div
              v-if="isMonitorOverwritten"
              class="float-right"
            >
              <b-badge
                v-b-popover.hover="'At least one monitor has been overwritten for this system.'"
                pill
                variant="info"
              >
                <small>Monitor overwritten <feather-icon icon="HelpCircleIcon" /></small>
              </b-badge>
            </div>
          </template>

          <ul
            v-if="activeMonitors.length > 0"
            style="list-style: none"
          >
            <li
              v-for="monitor in activeMonitors"
              :id="`monitor_${monitor.monitorId}`"
              :key="monitor.monitorId"
            >
              <monitor-status-icon
                :status="monitor.status"
                :text="monitor.shortMessage || monitor.exceptionMessage"
              />

              <b-popover
                custom-class="wide-popover"
                :target="`monitor_${monitor.monitorId}`"
                triggers="hover"
                :variant="popoverVariant(monitor.status)"
              >
                <template #title>
                  Detailed message
                </template>

                <span
                  v-if="monitor.message"
                  v-html="monitor.message"
                />
                <span v-else>No detailed message</span>
              </b-popover>
            </li>
          </ul>
          <div v-else>
            <monitor-status-icon
              :status="0"
              text="All monitors are OK"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-card title="Active alerts">
      <AlertTable
        v-if="activeAlerts.length > 0"
        :alerts="activeAlerts"
        :timezone="asup.timezone"
      />

      <p v-if="activeAlerts.length === 0">
        No active alerts
      </p>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, VBPopover, BPopover, BBadge,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'
import MonitorStatusIcon from '@/components/monitor/MonitorStatusIcon.vue'
import AlertTable from './components/AlertTable.vue'

import AsupStatusService from '@/service/asupStatus.service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    apexchart: VueApexCharts,
    MonitorStatusIcon,
    AlertTable,
    BPopover,
    BBadge,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      monitors: [],
      isLoading: false,
      capacityChartOptions: {
        chart: {
          type: 'radialBar',
          width: 50,
          height: 50,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: '50%',
            },
            track: {
              margin: 0,
            },
            dataLabels: {
              show: false,
            },
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    activeMonitors() {
      return this.monitors.filter(x => x.status !== 0)
    },
    activeAlerts() {
      const { alerts } = this.asup.details
      return alerts
        .filter(x => x.currentAlert === true)
        .concat().sort((a, b) => (a.postTime > b.postTime ? -1 : 1))
    },
    lastStatistic() {
      return this.asup.details.lastStatistic || {}
    },
    isMonitorOverwritten() {
      if (this.isHost && this.asup.isHostMonitorOverwritten) {
        return true
      }
      if (!this.isHost && this.asup.isTenantMonitorOverwritten) {
        return true
      }

      return false
    },
  },
  created() {
    AsupStatusService.getActiveListAsync({ asupSystemId: this.asup.id })
      .then(result => {
        this.monitors = result.items
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    popoverVariant(status) {
      switch (status) {
        case 0: return 'success'
        case 10: return 'info'
        case 20: return 'warning'
        case 30:
          return 'danger'
        default: return 'danger'
      }
    },
  },
}
</script>
